import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const jwtAuth = inject(JwtAuthService);

  if (jwtAuth.isLoggedIn()) {
    return true;
  } else {
    return router.createUrlTree(['/sessions/signin4'], {
      queryParams: {
        return: state.url,
      },
    });
  }
};
